/* CSS for studentparlamentet */
@import "../../../../../../lib/uio1/profile/css/util.less";

#hidnav a[href="#bottomnav"] {
  display: none;
}


h1, h2, h3, h4, h5, h6, .vrtx-frontpage-box>h2 a {
   color: #C0267D !important;
}

.not-for-ansatte {
  #main
    .button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search),
  #main input[type="button"]:not(.red),
  #main input[type="submit"]:not(.submit-comment-button):not(.red),
  #main input[type="cancel"]:not(.red),
  #main
    button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red):not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel) {
    background-color: @color-secondary--dark;
    color: @color-dark;
    .transition(all, 0.5s, ease-in);
    &:focus,
    &:hover {
      background-color: @color-secondary;
    }
  }
  .header-search {
    top: 18px;
    z-index: 10;
    input[type="text"] {
      background: @color-light;
      .placeholderColor(@color-dark, @color-dark) !important;
      color: @color-neutral--dark;
    }
    .header-search-expand {
      background: url(../uio1/profile/images/responsive/search-icon.svg) 9px
        9px no-repeat;
      background-size: 20px auto;
      &:hover,
      &:focus {
        background: url(../uio1/profile/images/responsive/search-icon-active.svg)
          9px 9px no-repeat;
        background-size: 20px auto;
      }
    }
    .header-search-expand,
    button {
      background-color: @color-secondary--dark;
      color: @color-dark;
      .transition(all, 0.5s, ease-in);
      &:focus,
      &:hover {
        background-color: @color-neutral;
        color: @color-light;
      }
    }
  }

  #head-wrapper {
    background: none @color-primary;
    #head {
      .uio-app-name {
        display: none;
      }
      height: 300px;
      .head-menu {
        right: 110px;
        a {
          color: @color-dark;
        }
        .vrtx-dropdown-wrapper ul li a {
          color: @color-link !important;
        }
        > .vrtx-login-manage-component .vrtx-dropdown-link {
          background: url(../images/language-dropdown-toggle-black.png) 100% 53%
            no-repeat;
        }
      }
      #header {
        img {
          height: 280px;
          display: block;
          margin-bottom: auto;
          margin-left: auto;
          margin-right: auto;
          padding-top: 20px;

          filter: invert(1); // white logo
        }
      }
    }
  }
}

#globalnav {
  background: @color-secondary none repeat scroll 0 0;
  ul li {
    a,
    &.vrtx-active-item a {
      color: @color-dark;
      &:hover,
      &:focus {
        background: @color-secondary--dark none repeat scroll 0 0;
        color: @color-dark;
        margin-top: 0;
      }
    }
    &.vrtx-active-item a {
      background: @color-light none repeat scroll 0 0;
      color: @color-neutral--dark;
    }
  }
}

#left-main ul.vrtx-breadcrumb-menu li {
  &.vrtx-child {
    a {
      color: @color-dark;
      background-color: @color-light;
    }
    span::before,
    a::before {
      content: " ";
    }
  }
  a {
    &.vrtx-marked {
      background-color: @color-neutral--light !important;
      color: @color-dark;
      border: none;
    }
    &:hover,
    &:focus {
      color: @color-primary--dark;
    }
  }
}

#main .grid-container .vrtx-frontpage-box:has(.ui-accordion) {
  height: fit-content !important;
}

.ui-accordion.ui-widget .ui-accordion-header {
  .ui-icon {
    background-image: url(/vrtx/dist/resources/images/icon-chevron-right.svg);
  }
  &.ui-accordion-header-active .ui-icon {
    background-image: url(/vrtx/dist/resources/images/icon-chevron-down.svg);
  }
}


.footer>.vrtx-search-scope a, a.all-comments, a.all-messages, a.more-url, a.vrtx-more, div.vrtx-more a {
  &:before {
    background-image: url(/vrtx/dist/resources/images/arrow-forward.svg);
  }
}

table th {
  font-weight: bold;
}

#bottomnav {
  display: none !important;
}

#uio-app-menu-wrapper,
#footer-wrapper {
  background: @footer-color;
}

//footer
.not-for-ansatte {
  &:not(.header-context) {
    .responsible-login .content {
      margin-left: 90px;
    }
  }
  #footer-wrapper {
    background-color: @color-secondary;
    *,
    a {
      color: @color-neutral--dark;
    }
    .vrtx-dropdown-component-toggled .vrtx-login-manage-link {
      background: rgba(0, 0, 0, 0) url("../uio1/images/dropdown-black.png")
        no-repeat scroll right center / 22px auto;
      &:focus,
      &:hover {
        background-image: url("../uio1/images/dropdown-black-hover.png");
      }
    }
  }
}

@media only screen and (max-width: 16cm) and (orientation: portrait),
  only screen and (max-width: 19cm) and (orientation: landscape) {
  .not-for-ansatte {
    #head-wrapper {
      background: none @color-primary;
      #head {
        height: auto;
        #header {
          img {
            padding: 10px;
          }
        }
      }
    }
    #menu-wrapper .menu li.active {
      background: @color-secondary none repeat scroll 0 0;
    }
  }
  .menu-search {
    input[type="text"] {
      border-right-width: 7em !important;
      padding-right: 0px;
    }
    button {
      background-color: @color-secondary;
      .transition(all, 0.5s, ease-in);
      &:focus,
      &:hover {
        background-color: @color-secondary--dark;
      }
    }
  }
}

/* Mixin */
.transition (@prop: all, @time: 1s, @ease: linear) {
  -webkit-transition: @prop @time @ease;
  -moz-transition: @prop @time @ease;
  -o-transition: @prop @time @ease;
  -ms-transition: @prop @time @ease;
  transition: @prop @time @ease;
}

/*------------------------------------*\
# COLOR
\*------------------------------------*/

// Primary, secondary etc...
@color-primary: #D83C95; // rosa // gammel farge: #4ec5e0 lyseblå //Gammel-gammel farge: rød #ee3338; 
@color-primary--dark: darken(spin(@color-primary, -5), 10%);
@color-secondary: #dfdfdf;
@color-secondary--dark: darken(spin(@color-secondary, -5), 10%);
@color-link: #2771bb;

// Neutral colors
@color-neutral--dark: #2b2b2b;
@color-neutral: #949494;
@color-neutral--light: #e4e4e4;

// Suplementary colors
@color-light: #ffffff;
@color-dark: #000000;
@footer-color: @color-neutral--light;
